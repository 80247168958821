import React from 'react';
import { BrowserRouter as Router, Route, Link, Routes } from 'react-router-dom';
import MultiStepForm from './Components/addUser';
import DisplayUsers from './Components/displayUser';

const AddUserPage = () => {
  return (
    <div>
      <h1>Add User</h1>
      <MultiStepForm />
    </div>
  );
};

const DisplayUsersPage = () => {
  return (
    <div>
      <h1>Display Users</h1>
      <DisplayUsers />
    </div>
  );
};

const App = () => {
  return (
    <Router>
      <div>

        <Routes>
          <Route path="/" element={<MultiStepForm />} />
          <Route path="/display" element={<DisplayUsersPage />} />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
