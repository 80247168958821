import React, { useState, useEffect } from 'react';

const DisplayUsers = () => {
  const [loadedUsers, setLoadedUsers] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch('https://igenergybackend.masterglobalprogress.com/users');
        const data = await response.json();
        setLoadedUsers(data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  return (
    <div>
      <h2>Users</h2>
      <ul>
        {loadedUsers.map((user) => (
          <li key={user.id}>
            Name: {user.name}, Email: {user.email}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DisplayUsers;
