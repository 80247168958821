import React, { useState } from 'react';
import './style.css';
import logoImage from './logo.png';
import techImage from './tech.png';

const MultiStepForm = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    linkedIn: '',
    idCard: '',
    language: '',
  });

  const steps = [
    { title: 'Name', color: step >= 1 ? '#171d62' : 'grey' },
    { title: 'Email', color: step >= 2 ? '#171d62' : 'grey' },
    { title: 'Linked', color: step >= 3 ? '#171d62' : 'grey' },
    { title: 'Submit', color: step >= 4 ? '#171d62' : 'grey' },
  ];

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    try {
      const response = await fetch('https://igenergybackend.masterglobalprogress.com/users', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        // Show success alert
        alert('Submission successful!');
        
        // Refresh the page
        window.location.reload();
      } else {
        // Handle other status codes or errors
        alert('Submission failed. Please try again.');
      }
    } catch (error) {
      // Handle network errors or exceptions
      console.error('Error:', error);
      alert('An error occurred. Please try again later.');
    }
  };
  

  return (
    <div className='Main'>
      <div>
        <div>
          <img src={techImage} style={{ width: '100px', height: '150px', marginRight: '150px' }} alt='Technology' />
          <img src={logoImage} style={{ width: '100px', height: '150px' }} alt='Logo' />
        </div>
      </div>
      <h1 style={{ textAlign: 'center' }}>Join Us</h1>

      {/* Progress Indicator */}
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px', marginLeft: '10px' }}>
        {steps.map((s, index) => (
          <React.Fragment key={index}>
            {index !== 0 && <div style={{ width: '50px' }} />}
            <div style={{ textAlign: 'center', margin: '0 25px' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <div
                  style={{
                    width: '30px',
                    height: '30px',
                    borderRadius: '50%',
                    border: `2px solid ${s.color}`,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: step >= index + 1 ? s.color : 'white',
                  }}
                >
                  <span style={{ color: step >= index + 1 ? 'white' : 'black' }}>
                    {index + 1}
                  </span>
                </div>
              </div>
              <div>{s.title}</div>
            </div>
            {index !== steps.length - 1 && <div style={{ flexGrow: 1, borderBottom: '2px solid black' }} />}
          </React.Fragment>
        ))}
      </div>

      {/* Form Components */}
      {step === 1 && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
          <input
            type="text"
            name="firstName"
            placeholder="First Name"
            value={formData.firstName}
            onChange={handleChange}
          />
          <input
            type="text"
            name="lastName"
            placeholder="Last Name"
            value={formData.lastName}
            onChange={handleChange}
          />
          <button style={{ background: '#171d62' }} onClick={nextStep}>Next</button>
        </div>
      )}
      {step === 2 && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
          <input
            type="email"
            name="email"
            placeholder="Email"
            value={formData.email}
            onChange={handleChange}
          />
          <input
            type="tel"
            name="phone"
            placeholder="Phone"
            value={formData.phone}
            onChange={handleChange}
          />
          <button onClick={nextStep} style={{ background: '#171d62' }}>Next</button>
          <button onClick={prevStep} style={{ background: '#171d62' }}>Previous</button>
        </div>
      )}
      {step === 3 && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
          <input
            type="text"
            name="linkedIn"
            placeholder="LinkedIn"
            value={formData.linkedIn}
            onChange={handleChange}
          />
          <input
            type="file"
            accept="image/*,application/pdf"
            name="idCard"
            onChange={handleChange}
            placeholder='CardID'
          />
          <button onClick={nextStep} style={{ background: '#171d62' }}>Next</button>
          <button onClick={prevStep} style={{ background: '#171d62' }}>Previous</button>
        </div>
      )}
      {step === 4 && (
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
          <input
            type="text"
            name="language"
            placeholder="What is your preferred programming language?"
            value={formData.language}
            onChange={handleChange}
          />
          <button onClick={handleSubmit} style={{ background: '#171d62' }}>Submit</button>
        </div>
      )}
    </div>
  );
};

export default MultiStepForm;
